import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AuthState } from '../_reducers/auth.reducer';
import { UserModel } from '../../_models/user.model';

export const selectUsersState = createFeatureSelector<AuthState>('auth');

export const currentAuthToken = createSelector(
  selectUsersState,
  (state: AuthState) => state.token
);

export const currentAuthUser = createSelector(
  selectUsersState,
  (auth: AuthState) => new UserModel(auth.userAuth)
);


export const currentForgotPassword = createSelector(
  selectUsersState,
  (auth: AuthState) => auth.forgotPassword
)
